import { useMediaQuery } from '@mui/material';

import { DeviceTypes } from 'types/app';

interface Props {
  children: any;
  showOn: DeviceTypes;
}

const ShowOnDevice: React.FC<Props> = ({ children, showOn }) => {
  const isMobile = useMediaQuery('(max-width: 992px)');

  if (showOn === DeviceTypes.Mobile && isMobile) return <>{children}</>;
  if (showOn === DeviceTypes.Desktop && !isMobile) return <>{children}</>;
  else return null;
};

export default ShowOnDevice;

export const DeviceIsMobile = () => {
  const isMobile = useMediaQuery('(max-width: 992px)');
  return isMobile;
};

export const DeviceIsHandHeld = () => {
  const isHandHeld = useMediaQuery('(max-width: 480px)');
  return isHandHeld;
};

/* istanbul ignore next */
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
