import React, { useEffect, useState } from 'react';

import { VegaDatePicker, VegaFieldError } from '@heartlandone/vega-react';
import { DateRange } from '@mui/x-date-pickers-pro';
import add from 'date-fns/add';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import isValid from 'date-fns/isValid';
import set from 'date-fns/set';
import sub from 'date-fns/sub';
import { useTranslation } from 'next-i18next';

import { ChartDateRange, Comparison } from 'types/chart';
import { DefaultDateFormat } from 'types/reports';
import { maxReportDate, minReportDate } from 'utility/helper/helperFunctions';

interface Props {
  selection: ChartDateRange;
  onSelect: (selection: ChartDateRange) => void;
}

const today = set(new Date(), { hours: 0, minutes: 0, seconds: 0 });

const CustomComparisonPicker: React.FC<Props> = ({ selection, onSelect }) => {
  const { t } = useTranslation('reports');

  const rangePlaceHolderLabels = [t('dateRange.startDate'), t('dateRange.endDate')];

  const [value, setValue] = useState<DateRange<Date>>(
    selection.compareEnd && selection.compareStart
      ? [sub(new Date(), { days: selection.compareStart }), sub(new Date(), { days: selection.compareEnd })]
      : [null, null],
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const startDate = set(sub(new Date(), { days: selection.start }), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const endDate = set(sub(new Date(), { days: selection.end }), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

  useEffect(() => {
    if (!selection.compareEnd || !selection.compareStart) setValue([null, null]);
  }, [selection.compareEnd, selection.compareStart]);

  const applyComparison = (date1: Date | null, date2: Date | null) => {
    setErrorMessage('');

    if (date1 != null && date2 != null) {
      if (date2 > maxReportDate()) {
        setValue([null, null]);
        setErrorMessage(t('errorMessages.endDate'));
        return;
      }

      if (date1 < minReportDate()) {
        setValue([null, null]);
        setErrorMessage(t('errorMessages.startDayMay'));
        return;
      }

      if (isEqual(date1, startDate)) {
        setValue([null, null]);
        setErrorMessage(t('errorMessages.startDay'));
        return;
      }

      if (!isValid(date1) || !isValid(date2)) {
        setValue([null, null]);
        setErrorMessage(t('errorMessages.invalid'));
        return;
      }

      const allowedDifference = differenceInDays(endDate, startDate);
      const currentDifference = differenceInDays(date2, date1);

      if (currentDifference !== allowedDifference) {
        const recommendedDate = add(date1, { days: allowedDifference });
        let errorMessage = `${t('errorMessages.invalidDifference1')} ${allowedDifference} ${t(
          'errorMessages.invalidDifference2',
        )}`;
        if (recommendedDate < maxReportDate())
          errorMessage = `${t('errorMessages.invalidDifference1')} ${allowedDifference} ${t(
            'errorMessages.invalidDifference3',
          )} ${format(recommendedDate, DefaultDateFormat)}?`;

        setValue([null, null]);
        setErrorMessage(errorMessage);
        return;
      }

      setValue([date1, date2]);
      onSelect({
        ...selection,
        range: Comparison.CUSTOM,
        comparison: Comparison.CUSTOM,
        compareStart: differenceInDays(today, date1),
        compareEnd: differenceInDays(today, date2),
      });
    }
  };

  return (
    <VegaFieldError message={errorMessage}>
      <VegaDatePicker
        mode="range"
        data-testid="transaction-chart-report-compare-date-selector"
        size="small"
        label={t('compareTo')}
        //@ts-ignore
        value={value}
        placeholder={rangePlaceHolderLabels}
        maxDate={format(maxReportDate(), DefaultDateFormat)}
        onVegaChange={(e: any) => {
          const [start, end] = e.target.value;

          if (start === null || end === null) {
            return;
          }

          const dateStart = new Date(start);
          const dateEnd = new Date(end);

          if (isEqual(dateStart, value[0] ?? new Date()) && isEqual(dateEnd, value[1] ?? new Date())) {
            return;
          }

          if (start !== '' && end !== '') {
            applyComparison(dateStart, dateEnd);
          }
        }}
        clearButton={true}
      />
    </VegaFieldError>
  );
};

export default CustomComparisonPicker;
