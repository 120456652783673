import { format } from "date-fns";

import { DataPoint } from 'types/chart';
import { BatchRange } from "types/reports";

export type Operation = (...values: number[]) => number;

export function calculateYValue(
  dataPoints: DataPoint[],
  comparisonData: DataPoint[] | undefined,
  operation: Operation,
) {
  if (!dataPoints.length) return 0;

  let yArray = dataPoints.flatMap((x) => parseFloat(x.yValue));

  if (comparisonData) {
    yArray = yArray.concat(comparisonData.flatMap((x) => parseFloat(x.yValue)));
  }

  const yValue = operation(...yArray);
  const exponential = Math.floor(Math.log10(Math.abs(yValue)));
  const calcAmount = Math.pow(10, exponential);

  const isOperationMin = operation === Math.min;
  let result = Math.round(yValue / calcAmount) * calcAmount || 0;
  result = isOperationMin ? result - calcAmount : result + calcAmount;
  return result > 0 && isOperationMin ? 0 : result;
}


export function getDefaultData(selectionStart: number, selectionEnd: number, range: string, isMoney: boolean) {
  let startDate = new Date();
  let endDate = new Date();

  if(range === BatchRange.Custom) {
    startDate.setDate(startDate.getDate() - selectionStart);
    endDate.setDate(endDate.getDate() - selectionEnd);
  } else if(range === BatchRange.Last7Days || range === '7days') {
    startDate.setDate(startDate.getDate() - 7);
    endDate.setDate(endDate.getDate() - 1);
  } else if(range === BatchRange.Last14Days || range === '14days') {
    startDate.setDate(startDate.getDate() - 14);
    endDate.setDate(endDate.getDate() - 1);
  } else if(range === BatchRange.Last30Days || range === '30days') {
    startDate.setDate(startDate.getDate() - 30);
    endDate.setDate(endDate.getDate() - 1);
  } else if(range === BatchRange.Last60Days) {
    startDate.setDate(startDate.getDate() - 60);
    endDate.setDate(endDate.getDate() - 1);
  }

  const output: DataPoint[] = [];

  const dateIter = new Date(startDate);
  while(dateIter.getTime() <= endDate.getTime()) {
    output.push({
      xValue: dateIter.toISOString(),
      yValue: '0',
      xValueFormatted: format(dateIter, 'MM/dd'),
      yValueFormatted: isMoney ? '$0.00' : '0',
    });
    dateIter.setDate(dateIter.getDate() + 1);
  }

  return output;
}
