import { useMemo } from 'react';

import { VegaBox } from '@heartlandone/vega-react';
import format from 'date-fns/format';
import sub from 'date-fns/sub';
import { TFunction } from 'next-i18next';

import { Comparison } from 'types/chart';

import styles from './legend.module.css';

const Legend = ({
  start,
  end,
  color,
  sx,
  formatDate,
  showDate = true,
  comparison,
  selectedDate = new Date(),
  t,
}: {
  start: number;
  end: number;
  color: string;
  sx?: any;
  formatDate?: string;
  showDate?: boolean;
  comparison?: Comparison;
  selectedDate?: Date;
  t?: TFunction;
}) => {
  const date = useMemo(() => {
    let startDate, endDate;
    if (comparison === Comparison.LAST_MONTH) {
      startDate = sub(selectedDate, { days: start, months: 1 });
      endDate = sub(selectedDate, { days: end, months: 1 });
    } else if (comparison === Comparison.LAST_YEAR) {
      startDate = sub(selectedDate, { days: start, years: 1 });
      endDate = sub(selectedDate, { days: end, years: 1 });
    } else {
      startDate = sub(selectedDate, { days: start });
      endDate = sub(selectedDate, { days: end });
    }
    if (!showDate) return;
    if (formatDate) return format(startDate, formatDate) + ' - ' + format(endDate, formatDate);

    let startMonth = t ? t(format(startDate, 'MMMM')) : format(startDate, 'MMMM');
    let endMonth = t ? t(format(endDate, 'MMMM')) : format(endDate, 'MMMM');
    let startYr = format(startDate, ' yyyy');
    let endYr = format(endDate, ' yyyy');

    if (startYr !== endYr) return startMonth + startYr + ' - ' + endMonth + endYr; // if years are dfferent, show both
    if (startMonth !== endMonth) {
      // if months are different but years are same, show months
      return startMonth + ' - ' + endMonth + endYr;
    }
    return startMonth + startYr; // if months are same, show only month
  }, [comparison, showDate, formatDate, selectedDate, start, end, t]);

  return (
    <VegaBox color="text-primary" className={styles.legend} style={sx}>
      <VegaBox className={styles.legendCircle} style={{ backgroundColor: color }} />
      {date}
    </VegaBox>
  );
};

export default Legend;
